import axios, { AxiosResponse } from 'axios';
import { Wordle } from '../models/wordle';

const apiClient = axios.create({
  baseURL: `${process.env.REACT_APP_BLEACHR_WEB_API}`, 
  timeout: 10000, 
  headers: {
    'Content-Type': 'application/json',
  },
});

const fanEngineApiClient = axios.create({
  baseURL: `${process.env.REACT_APP_ELIXIR_API_FOUR}`, 
  timeout: 10000, 
  headers: {
    'Content-Type': 'application/json',
  },
});

export const getWordleByAccountIdentifier = async (identifier: string): Promise<Wordle> => {
  const response: AxiosResponse<{ data: Wordle }> = await apiClient.get('/wordle/account', {
    headers: {
      'X-Bleachr': identifier,
    },
  });
  return response?.data?.data;
};

export const setRewardActions = async (identifier: string, fingerprint: string, token: string, tx_type: 'wordle_lost' | 'wordle_won'): Promise<any> => {
  const response: AxiosResponse<any> = await fanEngineApiClient.post('/reward_actions', {
    tx_type
  }, {
    headers: {
      Authorization: `Token token=${token}`,
      'X-Bleachr': identifier,
      fingerprint
    },
  });
  return response;
};
