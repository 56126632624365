import { IWordleAnswer, WordleAnswer } from "../models/wordle";

let answers: WordleAnswer;

export const updateWordleAnswers = (newAnswers: WordleAnswer) => answers = newAnswers;
export const getWordleAnswers = () => answers;


export const getAnswer = (name: string): IWordleAnswer | null => {
  if (name) {
    name = name.toLowerCase().trim()
    var result = answers?.five_letters?.find?.(({ answer }) => {
        return answer.toLowerCase().trim() === name
      })
    if(result != null){
        return result;
    }
    result = answers?.six_letters?.find?.(({ answer }) => {
        return answer.toLowerCase().trim() === name
      })
    if(result != null){
        return result;
    }
    result = answers?.seven_letters?.find?.(({ answer }) => {
        return answer.toLowerCase().trim() === name
      })
    if(result != null){
        return result;
    }
  }
  return null;
}
