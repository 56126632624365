import { Fragment } from 'react'
import { HowToPlayExample } from '../../models/wordle'
import { Cell } from '../grid/Cell'
import { BaseModal } from './BaseModal'
import { CharStatus } from '../../lib/statuses'

type Props = {
  isOpen: boolean
  handleClose: () => void
  title: string
  desc: string
  examples: HowToPlayExample[]
}

export const InfoModal = ({ isOpen, handleClose, title, desc, examples }: Props) => {
  return (
    <BaseModal title={title || "How to play"} isOpen={isOpen} handleClose={handleClose}>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        {desc || `Guess the tennis player in 4 tries. After each guess, the color of the tiles will
        change to show how close your guess was to the word and also give you a hint about the tennis player.`}
      </p>

      {
        examples.map((example, index) => {
          const random_answer = Math.floor(Math.random() * (examples.length + 1));
          return (
            <Fragment key={index}>
              <div className="flex justify-center mb-1 mt-4">
                {example.answer.split("").map((letter, idx) => (
                  <Cell
                    key={idx}
                    value={letter.trim()}
                    {
                    ...((idx - 1) === random_answer
                      ? { isRevealing: true, isCompleted: true, status: (example?.status  as CharStatus)|| 'correct'}
                      : {}
                    )
                    }
                  />
                ))}
              </div>
              <p className="text-sm text-gray-500 dark:text-gray-300">
                {example.caption}
              </p>
            </Fragment>
          );
        })
      }
    </BaseModal>
  )
}
