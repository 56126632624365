import { getWordListByLength } from '../constants/wordlist'
import { getValidGuessListByLength } from '../constants/validGuesses'
import { WRONG_SPOT_MESSAGE, NOT_CONTAINED_MESSAGE } from '../constants/strings'
import { getGuessStatuses } from './statuses'
import { default as GraphemeSplitter } from 'grapheme-splitter'
import { dayToIndex } from '../constants/days'
import { WordleCategory } from '../models/wordle'

export const getRandomCategory = (categories: WordleCategory[] = []) => {
  if (categories?.length) {
    const epochMs = new Date('January 1, 2022 00:00:00').valueOf()
    const now = new Date()
    let msInDay = 86400000
    if (process.env.NODE_ENV !== 'production'){
      msInDay = 86400000
    }
    const index = Math.floor((now.getTime() - epochMs) / msInDay);
    return categories[index % categories.length]
  }
}

export const isWordInWordList = (
  word: string,
  wordLength: number
) => {
  return (
    getWordListByLength(wordLength).includes(localeAwareLowerCase(word)) ||
    getValidGuessListByLength(wordLength).includes(localeAwareLowerCase(word))
  )
}

export const isWinningWord = (word: string, given_solution: string) => {
  return given_solution === word
}

// build a set of previously revealed letters - present and correct
// guess must use correct letters in that space and any other revealed letters
// also check if all revealed instances of a letter are used (i.e. two C's)
export const findFirstUnusedReveal = (word: string, guesses: string[], solution: string) => {
  if (guesses.length === 0) {
    return false
  }

  const lettersLeftArray = new Array<string>()
  const guess = guesses[guesses.length - 1]
  const statuses = getGuessStatuses(guess, solution)

  for (let i = 0; i < guess.length; i++) {
    if (statuses[i] === 'correct' || statuses[i] === 'present') {
      lettersLeftArray.push(guess[i])
    }
    if (statuses[i] === 'correct' && word[i] !== guess[i]) {
      return WRONG_SPOT_MESSAGE(guess[i], i + 1)
    }
  }

  // check for the first unused letter, taking duplicate letters
  // into account - see issue #198
  let n
  for (const letter of word) {
    n = lettersLeftArray.indexOf(letter)
    if (n !== -1) {
      lettersLeftArray.splice(n, 1)
    }
  }

  if (lettersLeftArray.length > 0) {
    return NOT_CONTAINED_MESSAGE(lettersLeftArray[0])
  }
  return false
}

export const unicodeSplit = (word: string) => {
  return word ? new GraphemeSplitter().splitGraphemes(word) : []
}

export const unicodeLength = (word: string) => {
  return unicodeSplit(word).length
}

export const localeAwareLowerCase = (text: string) => {
  return process.env.REACT_APP_LOCALE_STRING
    ? text?.toLocaleLowerCase(process.env.REACT_APP_LOCALE_STRING)
    : text?.toLowerCase()
}

export const localeAwareUpperCase = (text: string) => {
  return process.env.REACT_APP_LOCALE_STRING
    ? text?.toLocaleUpperCase(process.env.REACT_APP_LOCALE_STRING)
    : text?.toUpperCase()
}

export const getWordOfDay = (scheduled_days: string[] = []) => {
  // January 1, 2022 Game Epoch
  const epochMs = new Date('January 1, 2022 00:00:00').valueOf()
  const now = new Date()
  let msInDay = 86400000
  if (process.env.NODE_ENV !== 'production'){
    msInDay = 86400000
  }
  const index = Math.floor((now.getTime() - epochMs) / msInDay);

  const currentDayIndex = now.getUTCDay();
  const scheduledDayIndices = scheduled_days.map((day: string) => dayToIndex[day.toLowerCase()]).sort((a, b) => a - b);
  const closestFutureDayIndex = scheduledDayIndices.find(dayIndex => dayIndex > currentDayIndex) ?? scheduledDayIndices[0];
  const dayDifference = closestFutureDayIndex > currentDayIndex 
    ? closestFutureDayIndex - currentDayIndex 
    : (7 - currentDayIndex) + closestFutureDayIndex;

  const nextDayIndex = index + (!scheduled_days?.length ? 1 : dayDifference); 

  const nextday = nextDayIndex * msInDay + epochMs;

  const wordLength = getWordLength(index)
  const wordArray = getWordListByLength(wordLength)  
  return {
    solution: localeAwareUpperCase(wordArray[index % wordArray.length]),
    solutionIndex: index,
    tomorrow: nextday,
    wordLength: getWordLength(index)
  }
}

const getWordLength = (index: number) => {
  const indexOfLength = index % 3;
  if(indexOfLength === 0){return 5};
  if(indexOfLength === 1){return 6};
  if(indexOfLength === 2){return 7};
  return 0
}

export const { solution, solutionIndex, tomorrow, wordLength } = getWordOfDay()
