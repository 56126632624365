import {
  InformationCircleIcon,
  ChartBarIcon,
  CogIcon,
} from '@heroicons/react/outline'
import { useState, useEffect } from 'react'
import { Grid } from './components/grid/Grid'
import { Keyboard } from './components/keyboard/Keyboard'
import { InfoModal } from './components/modals/InfoModal'
import { StatsModal } from './components/modals/StatsModal'
import { SettingsModal } from './components/modals/SettingsModal'
import { getAnswer, updateWordleAnswers } from './constants/playerlist'
import {
  WIN_MESSAGES,
  GAME_COPIED_MESSAGE,
  NOT_ENOUGH_LETTERS_MESSAGE,
  WORD_NOT_FOUND_MESSAGE,
  CORRECT_WORD_MESSAGE,
  HARD_MODE_ALERT_MESSAGE,
} from './constants/strings'
import {
  MAX_CHALLENGES,
  REVEAL_TIME_MS,
  GAME_LOST_INFO_DELAY,
  WELCOME_INFO_MODAL_MS,
} from './constants/settings'
import {
  isWordInWordList,
  isWinningWord,
  findFirstUnusedReveal,
  unicodeLength,
  getWordOfDay,
  getRandomCategory
} from './lib/words'
import { addStatsForCompletedGame, loadStats } from './lib/stats'
import {
  loadGameStateFromLocalStorage,
  saveGameStateToLocalStorage,
  setStoredIsHighContrastMode,
  getStoredIsHighContrastMode,
} from './lib/localStorage'
import { default as GraphemeSplitter } from 'grapheme-splitter'
import './App.css'
import { AlertContainer } from './components/alerts/AlertContainer'
import { useAlert } from './context/AlertContext'
import { getWordleByAccountIdentifier } from './services/wordle.service'
import { useLocation } from 'react-router-dom';
import { parseQueryParams } from './utils/parseQueryParams';
import { IValidGuess, IWordleAnswer, Wordle, WordleCategory } from './models/wordle'
import { updateAnserws } from './constants/wordlist'
import { updateValidGuessList } from './constants/validGuesses'
import { ValidGuessesJSON, dictionary_valid_guesses } from './dictionary_valid_guesses'
import { days } from './constants/days'

function App() {
  const prefersDarkMode = window.matchMedia(
    '(prefers-color-scheme: dark)'
  ).matches

  const { showError: showErrorAlert, showSuccess: showSuccessAlert, showHint: showHintAlert } =
    useAlert()
  const [currentGuess, setCurrentGuess] = useState('')
  const [wordleCategory, setWordleCategory] = useState<string>('')
  const [isGameWon, setIsGameWon] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [wordLength, setWordLength] = useState<number>(0)
  const [solution, setSolution] = useState<string>('')
  const [fingerprint, setFingerprint] = useState<string>('')
  const [fanAuthToken, setFanAuthToken] = useState<string>('')
  const [accountIdentifier, setAccountIdentifier] = useState<string>('')
  const [tomorrow, setTomorrow] = useState<number>(0)
  const [solutionIndex, setSolutionIndex] = useState<number>(0)
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false)
  const [isStatsModalOpen, setIsStatsModalOpen] = useState(false)
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false)
  const [currentRowClass, setCurrentRowClass] = useState('')
  const [isGameLost, setIsGameLost] = useState(false)
  const [isDarkMode, setIsDarkMode] = useState(
    localStorage.getItem('theme')
      ? localStorage.getItem('theme') === 'dark'
      : prefersDarkMode
      ? true
      : false
  )
  const [isHighContrastMode, setIsHighContrastMode] = useState(
    getStoredIsHighContrastMode()
  )
  const [isRevealing, setIsRevealing] = useState(false)

  const [guesses, setGuesses] = useState<string[]>([])

  const [stats, setStats] = useState(() => loadStats())

  const [isHardMode, setIsHardMode] = useState(
    localStorage.getItem('gameMode')
      ? localStorage.getItem('gameMode') === 'hard'
      : false
  )

  const location = useLocation();
  const [wordle, setWordle] = useState<Wordle>();

  const getCategoriesForToday = (categories: WordleCategory[]): WordleCategory[] => {
    const scheduleDays = [...new Set(categories?.map?.(({ days_of_week }) => days_of_week)?.flatMap?.(x => x)?.filter?.((e) => e) || [])]
    const todayIdx = new Date().getDay()
    const today = days[todayIdx]
    const isTodayScheduled = scheduleDays.map(d => d.toLowerCase()).includes(today.toLowerCase())
    if (isTodayScheduled && categories?.length) {
      return categories.filter(({ days_of_week }) => (
        days_of_week?.map?.(d => d?.toLowerCase?.())?.includes?.(today?.toLowerCase?.())
      ))
    } 

    return []
  };

  useEffect(() => { 
    const queryParams = parseQueryParams(location.search);
    const accountParam = queryParams.get('account') || '';
    const tokenParam = queryParams.get('fan_auth_token') || ''
    const fingerprintParam = queryParams.get('fingerprint') || ''
    setFanAuthToken(tokenParam);
    setFingerprint(fingerprintParam);
    setAccountIdentifier(accountParam);
    if (accountParam && fingerprint && fanAuthToken) {
      (async () => {
        try {
          setIsLoading(true);
          const wordle = await getWordleByAccountIdentifier(accountParam);
          let link = document.querySelector("link[rel~='icon']");
          if (!link) {
            link = document.createElement('link');
            (link as any).rel = 'icon';
            document.getElementsByTagName('head')?.[0]?.appendChild(link);
          }
          (link as any).href =  wordle?.logo_img_url;
          // document.title = wordle?.name || '&#65279;';
          document.documentElement.style.backgroundColor = wordle?.background_color || 'transparent';
          setWordle(wordle);
          let resWordleAnswers = wordle?.answers || {} as any
          let resWordleGuesses = wordle?.valid_guesses || {} as any
          const currentCategories = getCategoriesForToday(wordle.categories || []);
          const selectedCategory = getRandomCategory(currentCategories)
          // All answers must be in the guesses list
          for (const key in resWordleAnswers) {
            if (!resWordleGuesses?.[key]) {
              resWordleGuesses[key] = resWordleAnswers[key].map(({ answer, id }: IWordleAnswer) => ({
                account_id: wordle.account_id,
                category_id: null,
                id: `g-${id}`,
                type: key,
                valid_guess: answer,
              }))
            } else {
              // eslint-disable-next-line no-loop-func
              resWordleAnswers?.[key]?.forEach?.(({ id, answer, account_id }: IWordleAnswer) => {
                const match = resWordleGuesses[key].find(({ valid_guess }: IValidGuess) => answer.toLowerCase() === valid_guess.toLowerCase())
                if (!match) {
                  resWordleGuesses[key] = [
                    ...resWordleGuesses[key],
                    {
                      account_id,
                      category_id: null,
                      id: `g-${id}`,
                      type: key,
                      valid_guess: answer,
                    }
                  ]
                }
              })
            }
            // Filtering answers by selectedCategory
            resWordleAnswers[key] = (resWordleAnswers[key]).filter(({ category_id }: IWordleAnswer) => (
              selectedCategory?.id && selectedCategory.id === category_id
            ))
          }
          updateAnserws(resWordleAnswers);
          updateWordleAnswers(resWordleAnswers);
          if (wordle?.supplement_valid_words_with_dictionary) {
            // const dictionaryGuessesSort = {five_letters: [],six_letters:[], seven_letters: []}
            //  = DictionaryGuesses as { five_letters: IValidGuess[], six_letters: IValidGuess[], seven_letters: IValidGuess[]};

            const sorted = dictionary_valid_guesses.reduce((acc:{five_letters: ValidGuessesJSON[],six_letters: ValidGuessesJSON[], seven_letters: ValidGuessesJSON[]}, current) => {
                switch (current.type) {
                    case 'five_letters' : {
                        acc.five_letters.push(current)
                        break
                    }
                    case 'six_letters' : {
                        acc.six_letters.push(current)
                        break
                    }
                    case 'seven_letters' : {
                        acc.seven_letters.push(current)
                        break
                    }
                }
                return acc
            }, {five_letters: [],six_letters: [], seven_letters: []})

            resWordleGuesses = {
              five_letters: [
                ...resWordleGuesses?.five_letters,
                ...sorted?.five_letters,
              ],
              six_letters: [
                ...resWordleGuesses?.six_letters,
                ...sorted?.six_letters,
              ],
              seven_letters: [
                ...resWordleGuesses?.seven_letters,
                ...sorted?.seven_letters,
              ],
            }
          }
          updateValidGuessList(resWordleGuesses);
          const scheduleDays = [...new Set(wordle?.categories?.map?.(({ days_of_week }) => days_of_week)?.flatMap?.(x => x)?.filter?.((e) => e) || [])]
          
          const { wordLength, solution, tomorrow, solutionIndex } = getWordOfDay(scheduleDays); // wordle?.game_days

          if (selectedCategory?.id) {
            setWordleCategory(selectedCategory.name);
          }
          
          setSolutionIndex(solutionIndex)
          setTomorrow(tomorrow);
          setSolution(solution)
          setWordLength(wordLength)
          setGuesses((_) => {
            const loaded = loadGameStateFromLocalStorage()
            const gameWasWon = loaded?.guesses?.includes(loaded?.solution)
            if (loaded?.solution !== solution) {
              return []
            }
            if (gameWasWon) {
              setIsGameWon(true)
            }
            if (loaded?.guesses?.length === MAX_CHALLENGES && !gameWasWon) {
              setIsGameLost(true)
              showErrorAlert(CORRECT_WORD_MESSAGE(solution), {
                persist: true,
              })
            }
            
            return loaded?.guesses || []
          })
        } catch (error) {
          console.error('Error fetching wordle', error);
        } finally {
          setIsLoading(false);
        }
      })();
    }
  }, [fanAuthToken, fingerprint, location.search, showErrorAlert]);

  useEffect(() => {
    // if no game state on load,
    // show the user the how-to info modal
    if (!loadGameStateFromLocalStorage()) {
      setTimeout(() => {
        setIsInfoModalOpen(true)
      }, WELCOME_INFO_MODAL_MS)
    }
  }, [])

  useEffect(() => {
    if (isDarkMode) {
      document.documentElement.classList.add('dark')
    } else {
      document.documentElement.classList.remove('dark')
    }

    if (isHighContrastMode) {
      document.documentElement.classList.add('high-contrast')
    } else {
      document.documentElement.classList.remove('high-contrast')
    }
  }, [isDarkMode, isHighContrastMode])

  const handleDarkMode = (isDark: boolean) => {
    setIsDarkMode(isDark)
    localStorage.setItem('theme', isDark ? 'dark' : 'light')
  }

  const handleHardMode = (isHard: boolean) => {
    if (guesses.length === 0 || localStorage.getItem('gameMode') === 'hard') {
      setIsHardMode(isHard)
      localStorage.setItem('gameMode', isHard ? 'hard' : 'normal')
    } else {
      showErrorAlert(HARD_MODE_ALERT_MESSAGE)
    }
  }

  const handleHighContrastMode = (isHighContrast: boolean) => {
    setIsHighContrastMode(isHighContrast)
    setStoredIsHighContrastMode(isHighContrast)
  }

  const clearCurrentRowClass = () => {
    setCurrentRowClass('')
  }

  useEffect(() => {
    if (!isLoading && solution) {
      saveGameStateToLocalStorage({ guesses, solution })
    }
  }, [guesses, solution, isLoading])

  useEffect(() => {
    if (isGameWon) {
      const winMessage =
        WIN_MESSAGES[Math.floor(Math.random() * WIN_MESSAGES.length)]
      const delayMs = REVEAL_TIME_MS * wordLength

      showSuccessAlert(winMessage, {
        delayMs,
        onClose: () => setIsStatsModalOpen(true),
      });
    }

    if (isGameLost) {
      setTimeout(() => {
        setIsStatsModalOpen(true)
      }, GAME_LOST_INFO_DELAY);
    }
  }, [isGameWon, isGameLost, showSuccessAlert, wordLength])

  const onChar = (value: string) => {
    if (
      unicodeLength(`${currentGuess}${value}`) <= wordLength &&
      guesses.length < MAX_CHALLENGES &&
      !isGameWon
    ) {
      setCurrentGuess(`${currentGuess}${value}`)
    }
  }

  const onDelete = () => {
    setCurrentGuess(
      new GraphemeSplitter().splitGraphemes(currentGuess).slice(0, -1).join('')
    )
  }

  const onEnter = async () => {
    if (isGameWon || isGameLost) {
      return
    }

    if (!(unicodeLength(currentGuess) === wordLength)) {
      setCurrentRowClass('jiggle')
      return showErrorAlert(NOT_ENOUGH_LETTERS_MESSAGE, {
        onClose: clearCurrentRowClass,
      })
    }

    if (!isWordInWordList(currentGuess, wordLength)) {
      setCurrentRowClass('jiggle')
      return showErrorAlert(WORD_NOT_FOUND_MESSAGE, {
        onClose: clearCurrentRowClass,
      })
    }

    // enforce hard mode - all guesses must contain all previously revealed letters
    if (isHardMode) {
      const firstMissingReveal = findFirstUnusedReveal(currentGuess, guesses, solution)
      if (firstMissingReveal) {
        setCurrentRowClass('jiggle')
        return showErrorAlert(firstMissingReveal, {
          onClose: clearCurrentRowClass,
        })
      }
    }

    setIsRevealing(true)
    // turn this back off after all
    // chars have been revealed
    setTimeout(() => {
      setIsRevealing(false)
    }, REVEAL_TIME_MS * wordLength)

    const winningWord = isWinningWord(currentGuess, solution)

    if (
      unicodeLength(currentGuess) === wordLength &&
      guesses.length < MAX_CHALLENGES &&
      !isGameWon
    ) {
      setGuesses([...guesses, currentGuess])
      setCurrentGuess('')

      if (winningWord) {
        const res = await addStatsForCompletedGame(stats, guesses.length, accountIdentifier, fingerprint, fanAuthToken)
        setStats(res)
        return setIsGameWon(true)
      }
      buildHint(guesses.length)
      if (guesses.length === MAX_CHALLENGES - 1) {
        const res = await addStatsForCompletedGame(stats, guesses.length + 1, accountIdentifier, fingerprint, fanAuthToken)
        setStats(res)
        setIsGameLost(true)
        showErrorAlert(CORRECT_WORD_MESSAGE(solution), {
          persist: true,
          delayMs: REVEAL_TIME_MS * wordLength + 1,
        })
      }
    }
  }

  const buildHint = (guessCount: number) => {
    const delayMs = REVEAL_TIME_MS * wordLength
    var message = "";
    var answer = getAnswer(solution);
    if (answer?.id) {
      if(guessCount >= 0){
        message += answer?.hint1;
      }
      if(guessCount >= 1){
        message += ". " + answer?.hint2
      }
      if(guessCount >= 2){
        message += ". " + answer?.hint3
      }
      showHintAlert(message, {
        delayMs,
        persist: true,
        onClose: () => buildHint(guessCount),
      })
    }
  }

  return (
    (!isLoading && wordle?.id) ? <div className="pt-2 pb-8 max-w-7xl mx-auto sm:px-6 lg:px-8">
      <div className="flex w-80 mx-auto items-center mb-4 mt-20">
      {wordle?.logo_img_url && <img className="h-7 w-7 mr-2 cursor-pointer dark:stroke-white" src={wordle?.logo_img_url} alt="logo" />}
        <h1 className="text-xl ml-2.5 grow font-bold dark:text-white">
          {wordle?.name || ''}
        </h1>
        <InformationCircleIcon
          className="h-6 w-6 mr-2 cursor-pointer dark:stroke-white"
          onClick={() => setIsInfoModalOpen(true)}
        />
        <ChartBarIcon
          className="h-6 w-6 mr-3 cursor-pointer dark:stroke-white"
          onClick={() => setIsStatsModalOpen(true)}
        />
        <CogIcon
          className="h-6 w-6 mr-3 cursor-pointer dark:stroke-white"
          onClick={() => setIsSettingsModalOpen(true)}
        />
      </div>
      {wordleCategory && <div className="flex mb-[1rem] justify-center items-center text-xl font-bold dark:text-white">
          <div>Category: {wordleCategory}</div>
      </div>}
      <Grid
        solution={solution}
        wordLength={wordLength}
        guesses={guesses}
        currentGuess={currentGuess}
        isRevealing={isRevealing}
        currentRowClassName={currentRowClass}
      />
      <Keyboard
        solution={solution}
        wordLength={wordLength}
        onChar={onChar}
        onDelete={onDelete}
        onEnter={onEnter}
        guesses={guesses}
        isRevealing={isRevealing}
      />
      <InfoModal
        examples={wordle?.how_to_play_examples || []}
        title={wordle?.how_to_play_title || "How to play"}
        desc={wordle?.how_to_play_desc || ""}
        isOpen={isInfoModalOpen}
        handleClose={() => setIsInfoModalOpen(false)}
      />
      <StatsModal
        solutionIndex={solutionIndex}
        gameTitle={wordle?.name || ''}
        accountName={wordle?.account_name || ''}
        solution={solution}
        tomorrow={tomorrow}
        isOpen={isStatsModalOpen}
        handleClose={() => setIsStatsModalOpen(false)}
        guesses={guesses}
        gameStats={stats}
        isGameLost={isGameLost}
        isGameWon={isGameWon}
        handleShare={() => showSuccessAlert(GAME_COPIED_MESSAGE)}
        isHardMode={isHardMode}
        isDarkMode={isDarkMode}
        isHighContrastMode={isHighContrastMode}
      />
      <SettingsModal
        isOpen={isSettingsModalOpen}
        handleClose={() => setIsSettingsModalOpen(false)}
        isHardMode={isHardMode}
        handleHardMode={handleHardMode}
        isDarkMode={isDarkMode}
        handleDarkMode={handleDarkMode}
        isHighContrastMode={isHighContrastMode}
        handleHighContrastMode={handleHighContrastMode}
      />

      <AlertContainer />
    </div>
    : <></>
  )
}

export default App


