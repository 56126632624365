import { ValidGuess } from "../models/wordle"

let validGuessList: ValidGuess;
export const updateValidGuessList = (newValidGuessList: ValidGuess) => validGuessList = newValidGuessList;
export const getValidGuessList = () => validGuessList;

export const getValidGuessListByLength = (
  length: number,
) => {
  if(length === 5){return validGuessList?.five_letters?.map?.(({ valid_guess }) => valid_guess.toLowerCase().trim()) || []};
  if(length === 6){return validGuessList?.six_letters?.map?.(({ valid_guess }) => valid_guess.toLowerCase().trim()) || []};
  if(length === 7){return validGuessList?.seven_letters?.map?.(({ valid_guess }) => valid_guess.toLowerCase().trim()) || []};
  return []
}
