import { WordleAnswer } from "../models/wordle"

let answers: WordleAnswer;

export const updateAnserws = (newAnswers: WordleAnswer) => answers = newAnswers;
export const getAnswers = () => answers;

export const getWordListByLength = (length: number) => {
  if(length === 5){return answers?.five_letters?.map?.(({ answer }) => answer.toLowerCase().trim()) || []};
  if(length === 6){return answers?.six_letters?.map?.(({ answer }) => answer.toLowerCase().trim()) || []};
  if(length === 7){return answers?.seven_letters?.map?.(({ answer }) => answer.toLowerCase().trim()) || []};
  return []
}